<template>
  <v-app id="app">
    <v-navigation-drawer
      v-if="
        !$store.state.skipLogin &&
          $router.currentRoute.name != 'login' &&
          $router.currentRoute.name != 'Verify Email' &&
          $store.getters.user &&
          $store.getters.navbar
      "
      v-model="drawer"
      width="270"
      app
    >
      <v-row class="pa-8 pr-4 flex justify-space-between secondary--text">
        <div>
          <h4 class="font-weight-regular">Hello!</h4>
          <h2 class="font-weight-regular">
            {{ $store.getters.user.name.split(" ")[0] }}
          </h2>
        </div>
        <v-btn fab color="primary" @click="$router.push({ name: 'General' })">
          <v-avatar size="50">
            <img :src="previewImage" alt="GoDial" />
          </v-avatar>
        </v-btn>
      </v-row>

      <v-autocomplete
        v-if="
          $store.getters.user.role.toLowerCase() == 'manager' ||
            $store.getters.user.role.toLowerCase() == 'teammanager'
        "
        v-model="selectedTeam"
        :items="teams"
        item-text="name"
        item-value="id"
        label="Teams"
        class="mx-2 primary--text"
        solo
        background-color="secondary"
        color="primary"
        hide-details
        dense
      >
        <template v-slot:selection="data">
          <span class="text-body-2 text-truncate inverted--text">
            Team: {{ data.item.name }}
          </span>
        </template>
      </v-autocomplete>

      <v-list solo dense class="my-1">
        <template v-for="item in items">
          <!-- <v-row
            v-if="item.heading"
            :key="item.heading"
            align="center"
            :data-intercom-target="`assignments${item.text}`"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 secondary--text">EDIT</a>
            </v-col>
          </v-row> -->
          <v-list-group
            v-if="item.children && item.visible"
            :key="item.text"
            v-model="item.model"
            class="mx-2"
            :data-intercom-target="`assignments${item.text}`"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item class="ml-n4">
                <v-list-item-content>
                  <v-list-item-title
                    class="subtitle-1 secondary--text font-weight-regular"
                  >
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-badge
                v-if="item.beta"
                color="primary"
                content="BETA"
                class="custom"
              ></v-badge>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              active-class="primarygradMenu white--text rounded "
              link
              :to="child.link"
            >
              <v-list-item-icon v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="subtitle-1 secondary--text font-weight-regular"
                  >{{ child.text }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else-if="!item.children && item.visible"
            :key="item.text"
            active-class="primarygradMenu white--text mx-2 rounded"
            class="mx-2"
            link
            :to="item.link"
            :data-intercom-target="`assignments${item.text}`"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-regular"
                >{{ item.text }}
                <!-- <v-badge v-if="item.beta" color="primary" content="BETA" inline></v-badge> -->
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template>
          <v-list-item
            v-if="$store.state.user.role == 'Manager'"
            active-class="primarygradMenu white--text mx-2 rounded"
            class="mx-2"
            link
            to="/integration"
          >
            <v-list-item-action>
              <v-icon>mdi-account-switch</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-regular">
                Integration
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- Inbox -->
        <!-- <template>
          <v-list-item
            v-if="$store.state.user.role == 'Manager'"
            active-class="primarygrad white--text mx-2 rounded"
            class="mx-2"
            link
            to="/inbox"
          >
            <v-list-item-action>
              <v-icon>mdi-message-bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-regular">
                Inbox
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template> -->

        <template>
          <v-list-item
            v-if="$store.state.user.role == 'Manager'"
            active-class="primarygradMenu white--text mx-2 rounded"
            class="mx-2"
            link
            to="/recording"
          >
            <v-list-item-action>
              <v-icon>mdi-headphones-settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-regular">
                Recording
                <v-badge
                  color="primary"
                  style="padding-left:5px"
                  content="BETA"
                ></v-badge>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template>
          <v-list-item
            active-class="primarygradMenu white--text mx-2 rounded"
            class="mx-2"
            link
            to="/settings"
          >
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-regular">
                Settings
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="isMobile()">
          <v-list-item
            active-class="primarygradMenu white--text mx-2 rounded"
            class="mx-2"
            @click="logout"
          >
            <v-list-item-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-regular">
                Logout
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-if="!isMobile()">
          <div class="pa-2">
            <v-btn depressed block @click="logout">
              <v-icon class="mr-3">exit_to_app</v-icon>
              Logout
            </v-btn>
          </div>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="
        !$store.state.skipLogin &&
          $router.currentRoute.name != 'login' &&
          $store.getters.user &&
          $store.getters.navbar
      "
      class="inverted"
      elevate-on-scroll
      app
    >
      <v-btn v-if="SubPage" icon @click="$router.go(-1)">
        <v-icon class="secondary--text">arrow_back</v-icon>
      </v-btn>

      <v-app-bar-nav-icon
        v-else
        class="secondary--text"
        data-intercom-target="navbarmenu"
        @click.stop="drawer = !drawer"
      />

      <v-toolbar-title class="ml-0 pl-4 seconday"
        ><b>{{ routeName() }}</b></v-toolbar-title
      >

      <!-- $router.currentRoute.name -->

      <v-spacer />

      <v-btn
        class="ma-md-2"
        small
        icon
        fab
        @click="
          $vuetify.theme.dark = !$vuetify.theme.dark;
          setDarkMode($vuetify.theme.dark);
        "
      >
        <v-icon color="secondary">mdi-weather-sunny</v-icon>
      </v-btn>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-md-2"
            small
            icon
            fab
            to="/help"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="secondary">mdi-information</v-icon>
          </v-btn>
        </template>
        <span>Contact Us</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-md-2"
            small
            icon
            fab
            to="/settings"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="secondary">settings</v-icon>
          </v-btn>
        </template>
        <span>Settings</span>
      </v-tooltip>

      <v-btn
        v-show="
          [
            'Reports',
            'Team and Members',
            'Recording',
            'Message Templates',
            'Lists & Contacts',
            'Dashboard',
            'CRM',
            'Automation',
            'Custom Status',
            'DNC',
          ].includes($router.currentRoute.name)
        "
        class="ma-md-2"
        small
        icon
        fab
        :href="watchVideoTutorials"
        target="_blank"
      >
        <v-icon color="secondary">play_circle_filled</v-icon>
      </v-btn>

      <!-- v-show=" !['Pipeline'].includes($router.currentRoute.name) " -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            small
            icon
            fab
            class="ma-md-2"
            :disabled="reloading"
            v-bind="attrs"
            @click="refresh"
            v-on="on"
          >
            <v-icon color="secondary">refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </v-app-bar>

    <v-content class="inverted">
      <v-card
        v-if="$router.currentRoute.name != 'Login' && showExpiry"
        class="mx-7 my-2 px-5 py-3 d-block d-sm-flex justify-space-between elevation-2"
        two-line
      >
        <div class="d-flex align-center customBorderRadius">
          <v-btn color="primarygrad" fab small>
            <v-icon color="white">mdi-currency-inr</v-icon>
          </v-btn>
          <p class="mb-0 mx-3">
            Your organization's subscription
            <span v-if="expiryOver != true">is expiring soon</span>
            <span v-else>has ended</span>
            . Kindly renew to continue using GoDial.
          </p>
        </div>

        <div class="d-flex flex-row-reverse">
          <v-btn
            color="primarygrad"
            @click="$router.push({ name: 'Subscribe' })"
          >
            Renew
          </v-btn>
        </div>
      </v-card>

      <div tile flat class="pa-0 ma-0 pb-15">
        <router-view />
      </div>

      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primarygrad" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear indeterminate color="white" class="mb-0" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-content>
  </v-app>
</template>

<script>
import moment from "moment-timezone";
import fb from "./js/firebase";
import featureControl from "./js/featureControl";
import { mapGetters } from "vuex";
export default {
  name: "App",

  props: {
    source: String,
  },

  data: () => ({
    dialog: false,
    reloading: false,
    drawer: null,
    expiryOver: false,
    title: "",
    SubPage: false,
    previewImage: "",
    watchVideoTutorials: "",
  }),

  computed: {
    loading() {
      return this.$store.state.loading;
    },
    ...mapGetters(["ENDPOINT", "teams", "selectedTeam", "companyDetails"]),
    surveyLink() {
      return this.companyDetails.country === "India" ? "/survey-in" : "/survey";
    },
    items() {
      return [
        { icon: "mdi-laptop", text: "Dashboard", link: "/home", visible: true },
        {
          icon: "mdi-television-guide",
          text: "CRM",
          link: "/lists",
          visible: true,
        },
        {
          icon: "mdi-account-multiple-outline",
          text: "Team & Members",
          link: "/users",
          visible: true,
        },
        {
          icon: "mdi-whatsapp",
          text: "Whatsapp",
          beta: true,
          visible: true,
          children: [
            {
              icon: "mdi-message-text-outline",
              text: "Inbox",
              link: "/inbox",
              visible: true,
            },
            {
              icon: "mdi-google-circles-extended",
              text: "Templates",
              link: "/whatsapp-templates",
              visible: true,
            },
            {
              // icon: "mdi-robot-excited",
              icon: " mdi-memory",
              text: "Automation",
              link: "/whatsapp-automation",
              visible: true,
            },
            {
              icon: "mdi-cast",
              text: "Broadcast",
              link: "/broadcast",
              visible: true,
            },
            {
              icon: "mdi-chart-arc",
              text: "Reports",
              link: "/whatsapp-reports",
              visible: true,
            },
            {
              icon: "settings",
              text: "Settings",
              link: "/whatsapp-settings",
              visible: true,
            },
          ],
        },
        // {
        //   icon: "mdi-phone-voip",
        //   text: "Cloud Telephony",
        //   link: "/cloudtelephony",
        //   visible: this.companyDetails.defaultDialer != 'Telecmi',
        // },
        {
          icon: "mdi-chart-bar",
          text: "Reports and Analytics",
          visible: true,
          children: [
            {
              icon: "mdi-chart-line",
              text: "Master",
              link: "/reports",
              visible: true,
            },
            {
              icon: "mdi-shape-plus",
              text: "Summary",
              link: "/summary",
              visible: true,
            },
            {
              icon: " mdi-account-check",
              text: "Productivity",
              link: "/attendance",
              visible: true,
            },
            {
              icon: " mdi-star-outline",
              text: "Leaderboard",
              link: "/game",
              visible: true,
            },
            {
              icon: " mdi-fingerprint",
              text: "Attendance",
              link: "/productivity-attendance",
              visible: true,
            },
            {
              icon: "mdi-chart-pie",
              text: "Performance",
              link: "/performance",
              visible: true,
            },
            {
              icon: " mdi-pen",
              text: "Audit",
              link: "/Audit",
              visible: true,
            },
          ],
        },
        {
          icon: "mdi-tools",
          text: "Tools",
          visible: true,
          children: [
            {
              icon: "mdi-memory",
              text: "Automation",
              link: "/automation",
              visible: true,
            },
            {
              icon: "mdi-stocking",
              text: "Pipeline",
              link: "/stages",
              visible: featureControl.enabled("pipeline"),
            },
            {
              icon: "mdi-clipboard-outline",
              text: "Tasks",
              link: "/tasks",
              visible: true,
            },
            {
              icon: "mdi-pencil-box-outline",
              text: "GoCollect",
              link: "/form",
              visible: true,
            },
            {
              icon: "mdi-cube-outline",
              text: "Products",
              link: "/products",
              visible: true,
            },
            {
              icon: "mdi-lightbulb-outline",
              text: "Web Dialer",
              link: "/webdialer",
              visible: true,
              beta: true,
            },
          ],
        },
        {
          icon: "mdi-lightbulb-outline",
          text: "Marketing",
          visible: true,
          children: [
            {
              icon: "language",
              text: "Go Pages",
              link: "/gopages",
              visible: true,
            },
            {
              icon: "mdi-microphone-outline",
              text: "Voice Broadcast",
              link: this.surveyLink,
              visible: true,
              beta: true,
            },
            // {
            //   icon: "mdi-microphone-outline",
            //   text: "Voice Broadcast",
            //   link: "/survey-in",
            //   visible: true,
            //   beta: true,
            // },
            {
              icon: "mdi-clipboard-outline",
              text: "Web Form",
              link: "/integrate_form",
              visible: true,
              beta: true,
            },
          ],
        },
        // {
        //   icon: "mdi-lightbulb-outline",
        //   text: "Advanced",
        //   children: [
        //     {
        //       icon: "dialpad",
        //       text: "Web Dialer",
        //       link: "/webdialer",
        //       visible: true,
        //       beta: true,
        //     },
        //   ],
        // },

        // {
        //   icon: "mdi-cellphone-link",
        //   text: "Team App",
        //   link: "/apps",
        //   visible: true,
        // },

        {
          icon: "mdi-credit-card",
          text: "Subscribe",
          link: "/payments",
          visible: true,
        },
      ];
    },
    companyDetails() {
      return this.$store.getters.companyDetails;
    },

    teams() {
      return this.$store.getters.teams;
    },

    selectedTeam: {
      get(v) {
        return this.$store.getters.selectedTeam;
      },
      set(v) {
        this.$store.commit("setSelectedTeam", v);
      },
    },

    showExpiry() {
      if (this.$router.currentRoute.name == "Login") {
        return false;
      } else {
        try {
          var self = this;
          var company = this.$store.getters.companyDetails;
          var expiry = moment(company.plan.expiryDate);
          var now = moment();
          var planName = company.plan.name;

          var expiryBefore2Days = expiry.clone().subtract(2, "day");
          if (now.isAfter(expiry)) {
            self.expiryOver = true;
          } else {
            self.expiryOver = false;
          }

          if (planName == "GoDial Premium" && now.isAfter(expiry)) {
            return true;
          } else if (now.isAfter(expiryBefore2Days)) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          console.log(e);
          return false;
        }
      }
    },
  },

  watch: {
    companyDetails(value) {
      if (value !== undefined || value !== null) {
        this.computeLogo();
      }
    },

    $route(to) {
      this.title = to.name;
      this.SubPage = to.meta.subPage;

      this.watchVideoTutorials = "";

      if (to.name == "Reports") {
        this.watchVideoTutorials = "https://youtu.be/7Ry1wHihIMU";
      } else if (to.name == "Team and Members") {
        this.watchVideoTutorials = "https://youtu.be/IGKA_uaJqJU";
      } else if (to.name == "CRM") {
        this.watchVideoTutorials = "https://youtu.be/eKGePmpeAc0";
      } else if (to.name == "Automation") {
        this.watchVideoTutorials = "https://youtu.be/mJY9WJcSE9I";
      } else if (to.name == "Team App") {
        this.watchVideoTutorials = "https://youtu.be/WikjR6Cy5F4";
      } else if (to.name == "Custom Status") {
        this.watchVideoTutorials = "https://youtu.be/bAP5x8P7BGM";
      } else if (to.name == "DNC") {
        this.watchVideoTutorials = "https://youtu.be/XighqViPADE";
      } else if (to.name == "Recording") {
        this.watchVideoTutorials = "https://youtu.be/V1lNB9Nsrds";
      } else if (to.name == "Message Templates") {
        this.watchVideoTutorials = "https://youtu.be/w93olITYTlE";
      } else if (to.name == "Dashboard") {
        this.watchVideoTutorials = "https://youtu.be/UKKVCPVKOws";
      } else {
        this.watchVideoTutorials = "";
      }

      // try {
      //   window.Intercom("update");
      // } catch (e) {
      //   console.log(e);
      // }
    },
  },

  created() {
    var self = this;
    if (self.$router.currentRoute.name === "Reports") {
      this.watchVideoTutorials = "https://youtu.be/7Ry1wHihIMU";
    } else if (self.$router.currentRoute.name == "Team and Members") {
      this.watchVideoTutorials = "https://youtu.be/IGKA_uaJqJU";
    } else if (self.$router.currentRoute.name == "Recording") {
      this.watchVideoTutorials = "https://youtu.be/V1lNB9Nsrds";
    } else if (self.$router.currentRoute.name === "Lists & Contacts") {
      this.watchVideoTutorials = "https://youtu.be/eKGePmpeAc0";
    } else if (self.$router.currentRoute.name == "Message Templates") {
      this.watchVideoTutorials = "https://youtu.be/w93olITYTlE";
    } else if (self.$router.currentRoute.name === "Team App") {
      this.watchVideoTutorials = "https://youtu.be/WikjR6Cy5F4";
    } else if (self.$router.currentRoute.name === "Custom Status") {
      this.watchVideoTutorials = "https://youtu.be/bAP5x8P7BGM";
    } else if (self.$router.currentRoute.name === "DNC") {
      this.watchVideoTutorials = "https://youtu.be/XighqViPADE";
    }

    if (self.$store.state.user) {
      fb.init(self.$store.state, self);
    }

    console.log("companyDetails: ", this.companyDetails);
  },

  mounted() {
    // home screen app logo
    this.computeLogo();
  },

  methods: {
    isMobile() {
      if (window.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },
    routeName() {
      if (
        typeof this.$router.currentRoute.query != "undefined" &&
        typeof this.$router.currentRoute.query.topic == "undefined"
      ) {
        return this.$router.currentRoute.name;
      } else if (
        typeof this.$router.currentRoute.query != "undefined" &&
        this.$router.currentRoute.query.topic == "teams"
      ) {
        return "Team Management";
      } else if (
        typeof this.$router.currentRoute.query != "undefined" &&
        this.$router.currentRoute.query.topic == "assignments"
      ) {
        return "List Management";
      } else {
        return "";
      }
    },
    setDarkMode(v) {
      this.$store.commit("setDarkMode", v);
    },
    computeLogo() {
      if (this.companyDetails) {
        if (this.companyDetails.logo) {
          return (this.previewImage = `${this.$store.state.ENDPOINT}/storages/get/${this.companyDetails.logo}?access_token=${this.$store.getters.user.token}`);
        } else {
          return (this.previewImage = require("@/assets/logo.png"));
        }
      }
    },

    // refresh() {
    //   var currentRoute = this.$router.currentRoute.path;

    //   if (currentRoute == "/home")
    //     {this.$router.replace("/organization");}
    //   else
    //     {this.$router.replace("/home");}

    //   this.$nextTick(()=>{this.$router.replace(currentRoute)});
    // },

    refresh() {
      const self = this;
      self.reloading = true;

      var currentRoute = self.$route.fullPath;

      if (currentRoute == "/load") {
        self.$router.replace(
          "/home",
          function() {
            self.reloading = false;
          },
          function() {
            self.reloading = false;
          }
        );
      } else {
        self.$router.replace(
          "/load",
          function() {
            self.$nextTick(() => {
              self.$router.replace(currentRoute);
              self.reloading = false;
            });
          },
          function() {
            self.reloading = false;
          }
        );

        if (currentRoute == "/whatsapp-settings" || currentRoute == "/inbox") {
          self.$store.dispatch("getCompanyDetails", {
            companyId: self.$store.getters.user.companyId,
          });
        }
      }
    },

    logout() {
      //this.$store.dispatch("logout");
      const self = this;

      this.$http
        .post(this.$store.state.ENDPOINT + "/accounts/logout")
        .then(() => {
          self.$store.commit("setAllUsers", null);
          self.$store.commit("setUser", null);
          window.localStorage.clear();
          window.location.reload();

          fb.logout();
        })
        .catch(() => {
          self.$store.commit("setAllUsers", null);
          self.$store.commit("setUser", null);
          window.localStorage.clear();
          window.location.reload();
        });
    },

    sideNavUpdate() {
      this.$emit("sideNavUpdate");
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

html,
body {
  font-family: "Roboto", sans-serif;
}

#app {
  font-family: "Roboto", sans-serif;
}

.primarygrad {
  background-image: linear-gradient(to right, #37cf37, #37cf37);
}

.primarygrad.theme--light {
  background-image: linear-gradient(to right, #37cf37, #37cf37);
}

.primarygrad.theme--dark {
  background-image: linear-gradient(to right, #37cf37, #37cf37);
}

.infograd {
  background-image: linear-gradient(to right, #3ed1f3, #068fff);
}

.errorgrad {
  background-image: linear-gradient(to right, #f14968, #ff0303);
}

.notifygrad {
  background-image: linear-gradient(to right, #fc944f, #ed5b12);
}

.secondarygrad.theme--light {
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
}

.secondarygrad.theme--dark {
  background-image: linear-gradient(to bottom, #3b3b3b, #242424c4);
}

.bluegrad {
  background-image: linear-gradient(to top right, #190b97, #006efde8);
}

.thumb {
  background: #9cc134 !important;
}

.back.theme--light {
  background: #ecf4d9 !important;
}

.back.theme--dark {
  background: #3f4e1d31 !important;
}

.vti__input {
  color: inherit;
}

.custom {
  margin-top: 11px;
  margin-right: 30px;
}

.v-application .rounded {
  border-radius: 12px !important;
}

.v-navigation-drawer .v-list-item,
.v-list-group {
  overflow: visible;
  /* Change this as needed */
}

@media (min-width: 1200px) {
  .recordPadding {
    padding-left: 30%;
    padding-right: 30%;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .recordPadding {
    padding-left: 30%;
    padding-right: 30%;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .recordPadding {
    padding-left: 20%;
    padding-right: 20%;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .recordPadding {
    padding-left: 1%;
    padding-right: 1%;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .recordPadding {
    padding-left: 1%;
    padding-right: 1%;
  }
}
</style>
