const firebaseConfig = {
  apiKey: "AIzaSyDyvsAP4DI31z9TtRSrS4YtJslREfOoI40",
  authDomain: "godial-enterprise.firebaseapp.com",
  databaseURL: "https://godial-enterprise.firebaseio.com",
  projectId: "godial-enterprise",
  storageBucket: "godial-enterprise.appspot.com",
  messagingSenderId: "733503495552",
  appId: "1:733503495552:web:9d2c85ed3bf55900b1f565",
};

export default firebaseConfig;
