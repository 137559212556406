import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

const Home = (resolve) => require(["@/views/Home.vue"], resolve);
const Lists = (resolve) => require(["@/views/Lists.vue"], resolve);
const Loader = (resolve) => require(["@/components/loader"], resolve);
const Users = (resolve) => require(["@/views/Users.vue"], resolve);
const Contacts = (resolve) => require(["@/views/Contacts.vue"], resolve);
const Apps = (resolve) => require(["@/views/Apps.vue"], resolve);
const Settings = (resolve) => require(["@/views/Settings.vue"], resolve);
const Gopages = (resolve) => require(["@/views/Gopages.vue"], resolve);
const Leadforms = (resolve) => require(["@/views/Leadforms.vue"], resolve);
const InitialGopages = (resolve) =>
  require(["@/components/InitialGopages.vue"], resolve);
const Integration = (resolve) => require(["@/views/Integration.vue"], resolve);
const ChangePassword = (resolve) =>
  require(["@/views/change-password.vue"], resolve);
const DefaultDialer = (resolve) =>
  require(["@/views/default-dialer.vue"], resolve);

const CreateCampaign = (resolve) =>
  require(["@/views/voiceBroadcast/campaignCreate.vue"], resolve);

const SetPassword = (resolve) => require(["@/views/setPassword.vue"], resolve);

const Help = (resolve) => require(["@/views/help.vue"], resolve);
const Login = (resolve) => require(["@/views/Login.vue"], resolve);
const Reports = (resolve) => require(["@/views/Reports.vue"], resolve);
const APIToken = (resolve) => require(["@/views/APIToken.vue"], resolve);
const GoogleSheet = (resolve) => require(["@/views/GoogleSheet.vue"], resolve);
const ServeTel = (resolve) => require(["@/views/ServeTel.vue"], resolve);
const Summary = (resolve) => require(["@/views/summary.vue"], resolve);
const Storage = (resolve) => require(["@/views/Storage.vue"], resolve);
const Permissions = (resolve) => require(["@/views/Permissions.vue"], resolve);
const ResetPassword = (resolve) => require(["@/views/reset-password"], resolve);
const verify = (resolve) => require(["@/views/verify.vue"], resolve);
const Organization = (resolve) =>
  require(["@/views/organization.vue"], resolve);
const Dispo = (resolve) => require(["@/views/Dispo.vue"], resolve);
const Product = (resolve) => require(["@/views/Product.vue"], resolve);
const Fields = (resolve) => require(["@/views/Fields.vue"], resolve);
const MessageTemplates = (resolve) =>
  require(["@/views/templates.vue"], resolve);
const PreviewTemplate = (resolve) =>
  require(["@/views/preview-template.vue"], resolve);
const WhatsappPreviewTemplate = (resolve) =>
  require(["@/views/Whatsapp/preview-whatsapp-template.vue"], resolve);
const TasksPage = (resolve) => {
  require(["@/views/Tasks.vue"], resolve);
};

const ViewGoPagesCampaignPage = (resolve) => {
  require(["@/views/viewGoPagesCampaign.vue"], resolve);
};
const ErrorPage = (resolve) => {
  require(["@/views/ErrorPage.vue"], resolve);
};
const Management = (resolve) =>
  require(["@/views/Management.vue"], resolve).default;
const Audit = (resolve) => require(["@/views/Audit.vue"], resolve);
const Products = (resolve) => require(["@/views/ProductNew.vue"], resolve);
const UserSummary = (resolve) => require(["@/views/UserSummary.vue"], resolve);
const Knowlarity = (resolve) =>
  require(["@/views/CloudTelephony/Knowlarity.vue"], resolve);
const Exotel = (resolve) =>
  require(["@/views/CloudTelephony/Exotel.vue"], resolve);
const Integrately = (resolve) => require(["@/views/Integrately.vue"], resolve);
const Zapier = (resolve) => require(["@/views/Zapier.vue"], resolve);
const webHooks = (resolve) => require(["@/views/webHooks.vue"], resolve);
const Attendance = (resolve) => require(["@/views/attendance.vue"], resolve);
const ProductivityAttendance = (resolve) =>
  require(["@/views/attendanceNew.vue"], resolve);
const Performance = (resolve) => require(["@/views/performance.vue"], resolve);
const payment = (resolve) => require(["@/views/payments.vue"], resolve);
const Game = (resolve) => require(["@/views/game.vue"], resolve);
const Stages = (resolve) => require(["@/views/stages.vue"], resolve);
const Recording = (resolve) => require(["@/views/Recording.vue"], resolve);
const VerifyEmail = (resolve) => require(["@/views/verifyEmail.vue"], resolve);
const Indiamart = (resolve) =>
  require(["@/views/Integrations/Indiamart.vue"], resolve);

const IndiamartV2 = (resolve) =>
  require(["@/views/Integrations/IndiamartV2.vue"], resolve);
const ZohoCRM = (resolve) =>
  require(["@/views/Integrations/Zoho.vue"], resolve);
const Justdial = (resolve) =>
  require(["@/views/Integrations/Justdial.vue"], resolve);

const TradeIndia = (resolve) =>
  require(["@/views/Integrations/tradeIndia.vue"], resolve);

const Pabbly = (resolve) =>
  require(["@/views/Integrations/Pabbly.vue"], resolve);

const Quikr = (resolve) => require(["@/views/Integrations/quikr.vue"], resolve);

const Interakt = (resolve) =>
  require(["@/views/Integrations/interakt.vue"], resolve);

const Telecmi = (resolve) =>
  require(["@/views/Integrations/Telecmi.vue"], resolve);

const fbLeads = (resolve) =>
  require(["@/views/Integrations/fbLeads.vue"], resolve);
const Form = (resolve) => require(["@/views/form.vue"], resolve);
const newForm = (resolve) => require(["@/views/newForm.vue"], resolve);
const formResponse = (resolve) =>
  require(["@/views/formResponse.vue"], resolve);
const cloudTelephony = (resolve) =>
  require(["@/views/cloudTelephony.vue"], resolve);
const automation = (resolve) => require(["@/views/automation.vue"], resolve);

const SurveyCampaign = (resolve) =>
  require(["@/views/SurveyCampaign.vue"], resolve);

const SurveyCampaign2 = (resolve) =>
  require(["@/views/voiceBroadcast/surveyCampaign2.vue"], resolve);

const WebDialer = (resolve) => require(["@/views/webDialer.vue"], resolve);
const IntegrateForm = (resolve) =>
  require(["@/views/integrateForm.vue"], resolve);
const Inbox = (resolve) => require(["@/views/Whatsapp/Inbox.vue"], resolve);
const WhatsappSettings = (resolve) =>
  require(["@/views/Whatsapp/whatsapp-settings.vue"], resolve);

const Broadcast = (resolve) =>
  require(["@/views/Whatsapp/broadcast.vue"], resolve);

const WhatsappTemplates = (resolve) =>
  require(["@/views/Whatsapp/whatsapp-templates.vue"], resolve);

const WhatsappReports = (resolve) =>
  require(["@/views/Whatsapp/whatsapp-reports.vue"], resolve);
const WhatsappAutomation = (resolve) =>
  require(["@/views/Whatsapp/Automation.vue"], resolve);
// const Dnc = (resolve) => require(["@/views/Dnc.vue"], resolve);
// const Subscribe = (resolve) => require(["@/views/Subscribe.vue"], resolve);
// const store = resolve => require(["@/store/index"], resolve);
//const StackedCoupon = (resolve) => require(["@/views/stackedCoupon.vue"], resolve);

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "Error",
    component: ErrorPage,
    meta: {
      title: "GoDial - Error",
    },
  },
  {
    path: "/recording",
    name: "Recording",
    component: Recording,
    meta: {
      requiresAuth: true,
      title: "Recording",
    },
  },
  {
    path: "/home",
    name: "Dashboard",
    component: Home,
    meta: {
      requiresAuth: true,
      title: "Dashboard",
    },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      title: "Reset Password",
      hideCrisp: true,
    },
  },
  {
    path: "/verify",
    name: "Verify Email",
    component: verify,
    meta: {
      requiresAuth: false,
      title: "Verify Email",
      hideCrisp: true,
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      title: "Reports",
      hideCrisp: true,
    },
  },
  {
    path: "/load",
    name: "Load",
    component: Loader,
    meta: {
      requiresAuth: false,
      title: "Loading",
      hideCrisp: true,
    },
  },
  {
    path: "/change-password",
    name: "Change Password",
    component: ChangePassword,
    meta: {
      subPage: true,
      requiresAuth: true,
      title: "Change Password",
      hideCrisp: true,
    },
  },
  {
    path: "/default-dialer",
    name: "Set Default Dialer",
    component: DefaultDialer,
    meta: {
      subPage: true,
      requiresAuth: true,
      title: "Set Default Dialer",
      hideCrisp: true,
    },
  },
  {
    path: "/setPassword",
    name: "Set Password",
    component: SetPassword,
    meta: {
      requiresAuth: false,
      title: "Set Password",
    },
  },
  {
    path: "/organization",
    name: "General",
    component: Organization,
    meta: {
      subPage: true,
      requiresAuth: true,
      title: "General",
      hideCrisp: true,
    },
  },
  {
    path: "/lists",
    name: "CRM",
    component: Lists,
    meta: {
      requiresAuth: true,
      title: "CRM",
      hideCrisp: true,
    },
  },
  {
    path: "/webdialer",
    name: "Web Dialer",
    component: WebDialer,
    meta: {
      requiresAuth: true,
      title: "Web Dialer",
      hideCrisp: true,
    },
  },
  {
    path: "/manage",
    name: "Management",
    component: Management,
    meta: {
      requiresAuth: true,
      subPage: true,
      title: "Management",
      hideCrisp: true,
    },
  },
  {
    path: "/apps",
    name: "Team App",
    component: Apps,
    meta: {
      requiresAuth: true,
      title: "Team App",
      hideCrisp: true,
    },
  },
  // {
  //   path: "/dnc",
  //   name: "DNC",
  //   component: Dnc,
  //   meta: {
  //     requiresAuth: true,
  //     title: "DNC",
  //   },
  // },
  {
    path: "/inbox",
    name: "Inbox",
    component: Inbox,
    meta: {
      requiresAuth: true,
      title: "Inbox",
      hideCrisp: true,
    },
  },
  {
    path: "/whatsapp-settings",
    name: "Settings",
    component: WhatsappSettings,
    meta: {
      requiresAuth: true,
      title: "Whatsapp Settings",
      hideCrisp: true,
    },
  },
  {
    path: "/whatsapp-reports",
    name: "Reports",
    component: WhatsappReports,
    meta: {
      requiresAuth: true,
      title: "Whatsapp Reports",
      hideCrisp: true,
    },
  },
  {
    path: "/whatsapp-automation",
    name: "Whatsapp Automation",
    component: WhatsappAutomation,
    meta: {
      requiresAuth: true,
      title: "Whatsapp Automation",
      hideCrisp: true,
    },
  },
  {
    path: "/whatsapp-templates",
    name: "Templates",
    component: WhatsappTemplates,
    meta: {
      requiresAuth: true,
      title: "Whatsapp Templates",
      hideCrisp: true,
    },
  },
  {
    path: "/broadcast",
    name: "Broadcast",
    component: Broadcast,
    meta: {
      requiresAuth: true,
      title: "Broadcast",
      hideCrisp: true,
    },
  },
  {
    path: "/users",
    name: "Team and Members",
    component: Users,
    meta: {
      requiresAuth: true,
      title: "Team and Members",
      hideCrisp: true,
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
    meta: {
      requiresAuth: true,

      title: "Contacts",
      hideCrisp: true,
    },
  },
  {
    path: "/automation",
    name: "Automation",
    component: automation,
    meta: {
      requiresAuth: true,

      title: "Automation",
      hideCrisp: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      title: "Settings",
    },
  },
  {
    path: "/newgopages",
    name: "New Gopages",
    component: Gopages,
    props: true,
    meta: {
      requiresAuth: true,
      title: "Gopages",
      subPage: true,
    },
  },
  {
    path: "/gopages2",
    name: "Gopages",
    component: Leadforms,
    meta: {
      requiresAuth: true,
      title: "Lead Forms",
    },
  },
  {
    path: "/gopages",
    name: "Gopages 2",
    component: InitialGopages,
    props: true,
    meta: {
      requiresAuth: true,
      title: "Go Page new",
    },
  },
  {
    path: "/view-go-pages-campaign",
    name: "View GoPages",
    component: ViewGoPagesCampaignPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: "Go Pages",
    },
  },
  {
    path: "/integration",
    name: "Integration",
    component: Integration,
    meta: {
      requiresAuth: true,
      title: "Integration",
    },
  },
  {
    path: "/integration/indiamart",
    name: "Indiamart",
    component: Indiamart,
    meta: {
      requiresAuth: true,
      title: "Indiamart",
      subPage: true,
    },
  },
  {
    path: "/integration/indiamartV2",
    name: "Indiamart V2",
    component: IndiamartV2,
    meta: {
      requiresAuth: true,
      title: "Indiamart V2",
      subPage: true,
    },
  },
  {
    path: "/integration/justdial",
    name: "Justdial",
    component: Justdial,
    meta: {
      requiresAuth: true,
      title: "Justdial",
      subPage: true,
    },
  },
  {
    path: "/integration/tradeindia",
    name: "TradeIndia",
    component: TradeIndia,
    meta: {
      requiresAuth: true,
      title: "TradeIndia",
      subPage: true,
    },
  },
  {
    path: "/integration/pabbly",
    name: "Pabbly",
    component: Pabbly,
    meta: {
      requiresAuth: true,
      title: "Pabbly",
      subPage: true,
    },
  },
  {
    path: "/integration/quikr",
    name: "Quikr",
    component: Quikr,
    meta: {
      requiresAuth: true,
      title: "Quikr",
      subPage: true,
    },
  },
  {
    path: "/integration/interakt",
    name: "Interakt",
    component: Interakt,
    meta: {
      requiresAuth: true,
      title: "Interakt",
      subPage: true,
    },
  },
  {
    path: "/integration/telecmi",
    name: "Telecmi",
    component: Telecmi,
    meta: {
      requiresAuth: true,
      title: "Telecmi",
      subPage: true,
    },
  },
  {
    path: "/integration/zoho",
    name: "Zoho",
    component: ZohoCRM,
    meta: {
      requiresAuth: true,
      title: "Zoho CRM",
      subPage: true,
    },
  },
  {
    path: "/game",
    name: "Leaderboard",
    component: Game,
    meta: {
      requiresAuth: true,
      title: "Game",
      hideCrisp: true,
    },
  },
  {
    path: "/stages",
    name: "Pipeline",
    component: Stages,
    meta: {
      requiresAuth: true,
      title: "Pipeline",
    },
  },
  {
    path: "/form",
    name: "Forms",
    component: Form,
    meta: {
      requiresAuth: true,
      title: "Form",
    },
  },
  {
    path: "/newForm",
    name: "New Form",
    component: newForm,
    meta: {
      requiresAuth: true,
      title: "New Form",
    },
  },
  {
    path: "/editForm",
    name: "Edit Form",
    component: newForm,
    meta: {
      requiresAuth: true,
      title: "Edit Form",
    },
  },
  {
    path: "/formResponse",
    name: "Form Response",
    component: formResponse,
    meta: {
      requiresAuth: true,
      title: "Form Response",
    },
  },
  // {
  //   path: "/subscribe",
  //   name: "Subscribe",
  //   component: Subscribe,
  //   meta: {
  //     subPage: true,
  //     requiresAuth: true,
  //     title: "Subscribe",
  //   },
  // },
  {
    path: "/payments",
    name: "Subscribe",
    component: payment,
    meta: {
      requiresAuth: true,
      title: "Subscribe",
    },
  },
  {
    path: "/integration/fb",
    name: "Facebook Leads",
    component: fbLeads,
    meta: {
      requiresAuth: true,
      title: "Facebook Leads",
      subPage: true,
    },
  },
  {
    path: "/summary",
    name: "Summary Report",
    component: Summary,
    meta: {
      requiresAuth: true,
      title: "Summary Report",
      hideCrisp: true,
    },
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: Permissions,
    meta: {
      subPage: true,
      requiresAuth: true,
      title: "Permissions",
    },
  },
  {
    path: "/templates",
    name: "Message Templates",
    component: MessageTemplates,
    meta: {
      subPage: true,
      requiresAuth: true,
      title: "Message Templates",
      hideCrisp: true,
    },
  },
  {
    path: "/templates/:id",
    name: "Preview Template",
    component: PreviewTemplate,
    meta: {
      subPage: true,
      requiresAuth: true,
    },
  },
  {
    path: "/whatsapp-templates/:id",
    name: "Whatsapp Template",
    component: WhatsappPreviewTemplate,
    meta: {
      subPage: true,
      requiresAuth: true,
      hideCrisp: true,
    },
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: TasksPage,
    meta: {
      requiresAuth: true,
      title: "Tasks",
    },
  },
  {
    path: "/dispo",
    name: "Custom Status",
    component: Dispo,
    meta: {
      subPage: true,
      title: "Custom Status/Dispositions",
      requiresAuth: true,
      hideCrisp: true,
    },
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
    meta: {
      subPage: true,
      title: "Products",
      requiresAuth: true,
      hideCrisp: true,
    },
  },
  {
    path: "/UserSummary/:id",
    name: "User Summary",
    component: UserSummary,
    meta: {
      subPage: true,
      title: "User Summary",
      requiresAuth: true,
    },
  },
  {
    path: "/Fields",
    name: "Custom Fields",
    component: Fields,
    meta: {
      subPage: true,
      title: "Custom Fields",
      requiresAuth: true,
    },
  },
  {
    path: "/api_token",
    name: "External API",
    component: APIToken,
    meta: {
      subPage: true,
      title: "API Token Generator",
      requiresAuth: true,
    },
  },
  {
    path: "/google_sheet",
    name: "GoogleSheet",
    component: GoogleSheet,
    meta: {
      subPage: true,
      title: "GoogleSheet",
      requiresAuth: true,
    },
  },
  {
    path: "/integrate_form",
    name: "Integrate Form",
    component: IntegrateForm,
    meta: {
      subPage: true,
      title: "Integrate Form",
      requiresAuth: true,
    },
  },
  {
    path: "/servetel",
    name: "ServeTel",
    component: ServeTel,
    meta: {
      subPage: true,
      title: "ServeTel",
      requiresAuth: true,
    },
  },
  {
    path: "/storage",
    name: "Storage",
    component: Storage,
    meta: {
      subPage: true,
      title: "Storage",
      requiresAuth: true,
    },
  },
  {
    path: "/audit",
    name: "Audit",
    component: Audit,
    meta: {
      subPage: true,
      title: "Audit",
      requiresAuth: true,
    },
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: {
      subPage: false,
      title: "Products",
      requiresAuth: true,
    },
  },
  // {
  //   path: "/products",
  //   name: "Products",
  //   component: Products,
  //   meta: {
  //     subPage: true,
  //     title: "Products",
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/help",
    name: "Help",
    component: Help,
    meta: {
      title: "Help",
      requiresAuth: true,
    },
  },
  {
    path: "/cloudtelephony/knowlarity",
    name: "Knowlarity",
    component: Knowlarity,
    meta: {
      subPage: true,
      title: "Knowlarity",
      requiresAuth: true,
    },
  },
  {
    path: "/cloudtelephony/exotel",
    name: "Exotel",
    component: Exotel,
    meta: {
      subPage: true,
      title: "Exotel",
      requiresAuth: true,
    },
  },
  {
    path: "/cloudtelephony",
    name: "Cloud Telephony",
    component: cloudTelephony,
    meta: {
      title: "Cloud Telephony",
      requiresAuth: true,
      hideCrisp: false,
    },
  },
  {
    path: "/Web_Hooks",
    name: "webHooks",
    component: webHooks,
    meta: {
      subPage: true,
      title: "webHooks",
      requiresAuth: true,
    },
  },
  {
    path: "/integrately",
    name: "Integrately",
    component: Integrately,
    meta: {
      subPage: true,
      title: "Integrately",
      requiresAuth: true,
    },
  },
  {
    path: "/zapier",
    name: "Zapier",
    component: Zapier,
    meta: {
      subPage: true,
      title: "Zapier",
      requiresAuth: true,
    },
  },
  {
    path: "/attendance",
    name: "Productivity",
    component: Attendance,
    meta: {
      requiresAuth: true,
      title: "Productivity",
      hideCrisp: true,
    },
  },
  {
    path: "/productivity-attendance",
    name: "Productivity & Attendance",
    component: ProductivityAttendance,
    meta: {
      requiresAuth: true,
      title: "Productivity & Attendance",
      hideCrisp: true,
    },
  },
  {
    path: "/survey",
    name: "Voice Broadcast",
    component: SurveyCampaign,
    meta: {
      requiresAuth: true,
      title: "Voice Broadcast",
      hideCrisp: true,
    },
  },
  {
    path: "/survey-in",
    name: "Voice Broadcast India",
    component: SurveyCampaign2,
    meta: {
      requiresAuth: true,
      title: "Voice Broadcast",
      hideCrisp: true,
    },
  },
  {
    path: "/create-campaign/:id?",
    name: "Voice Broadcast Campaign",
    component: CreateCampaign,
    meta: {
      requiresAuth: true,
      title: "Create Campaign",
      hideCrisp: true,
      subPage: true,
    },
  },
  {
    path: "/performance",
    name: "Performance Report",
    component: Performance,
    meta: {
      requiresAuth: true,
      title: "Performance Report",
      hideCrisp: true,
    },
  },
  {
    path: "/verifyEmail",
    name: "Verify Email",
    component: VerifyEmail,
    meta: {
      requiresAuth: true,
      title: "Verification Email Pending",
      hideCrisp: true,
    },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  if (to.matched.some((record) => record.meta.hideCrisp)) {
    $crisp.push(["do", "chat:hide"]);
  } else {
    $crisp.push(["do", "chat:show"]);
  }

  // Check for requiresAuth guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var token = to.query.token;
    var user = to.query.user;

    if (token && user) {
      //perform login
      window.localStorage.clear();
      router.replace({
        query: null,
      });
      store.dispatch("externalLogin", {
        token,
        user,
        next,
        page: to.name,
      });
    } else if (!store.getters.user) {
      //check if auth params were passed
      // Go to login
      next({
        path: "/",
      });
    } else if (
      store.getters.config.useVerification &&
      store.getters.user.role == "Manager" &&
      !store.getters.user.isEmailVerified &&
      to.path != "/verify" &&
      to.path != "/verifyEmail" &&
      to.path != "/reset-password"
    ) {
      next("/verifyEmail");
    } else {
      next();
    }
  } else {
    // Proceed to route
    // If firstTime setup not done, redirect to first time setup
    if (
      store.getters.config.useVerification &&
      store.getters.user.role == "Manager" &&
      !store.getters.user.isEmailVerified &&
      to.path != "/verify" &&
      to.path != "/verifyEmail" &&
      to.path != "/reset-password"
    )
      next("/verifyEmail");
    else next();
  }
});

export default router;
