import Vue from "vue";
import { event, config, screen } from "vue-gtag";
import firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import moment from "moment-timezone";
import firebaseConfig from "../js/firebaseConfig.js";

var firebaseApp = initializeApp(firebaseConfig);

var mobile = function() {
  return false;
};

// Listen for realtime notification change

let firebaseAnalytics, firebaseMessaging;

if (!mobile()) {
  try {
    firebaseMessaging = firebaseApp.messaging();
    window.firebaseMessaging = firebaseMessaging;
    console.log("Firebase Messaging Setup ", firebaseMessaging);
  } catch (e) {
    console.log("Firebase Message", e);
  }

  try {
    firebaseAnalytics = firebaseApp.analytics();
  } catch (e) {
    console.log("Firebase analytics ", e);
  }
}

var fb = {};

fb.initCrisp = function(state) {
  try {
    $crisp.push(["set", "user:email", [state.user.email]]);
    $crisp.push(["set", "user:phone", [state.user.phone]]);
    $crisp.push(["set", "user:nickname", [state.user.name]]);
    $crisp.push([
      "set",
      "session:data",
      [
        [
          ["plan", state.companyDetails.plan.name],
          [
            "monthly_spend",
            typeof state.companyDetails.plan.amountPerMonth == "undefined"
              ? ""
              : state.companyDetails.plan.amountPerMonth,
          ],
          [
            "utm_source",
            typeof state.companyDetails.source.utm_source == "undefined"
              ? ""
              : state.companyDetails.source.utm_source,
          ],
          [
            "utm_campaign",
            typeof state.companyDetails.source.utm_campaign == "undefined"
              ? ""
              : state.companyDetails.source.utm_campaign,
          ],
          [
            "utm_medium",
            typeof state.companyDetails.source.utm_medium == "undefined"
              ? ""
              : state.companyDetails.source.utm_medium,
          ],
          [
            "utm_term",
            typeof state.companyDetails.source.utm_term == "undefined"
              ? ""
              : state.companyDetails.source.utm_term,
          ],
          ["created_at", moment(state.user.createdOn).format()],
          ["signed_up_at", moment(state.user.createdOn).format()],
          ["user_id", state.user.username],
          ["role", state.user.role],
        ],
      ],
    ]);
  } catch (e) {
    console.log(e);
  }
};

fb.init = function(state, app) {
  //   console.log("Firebase Message" , firebaseMessaging)
  const dispatch = app.$store.dispatch;
  try {
    firebaseAnalytics.setUserId(state.user.id);
    firebaseAnalytics.setUserProperties("name", state.user.name);
    firebaseAnalytics.setUserProperties("email", state.user.email);
    firebaseAnalytics.setUserProperties("company", state.companyDetails.name);
  } catch (e) {
    console.log(e);
  }

  //Switching to gtag from firebaseAnalytics
  try {
    config({
      userId: state.user.id,
      name: state.user.name,
      email: state.user.email,
      company: state.companyDetails.name,
    });
  } catch (e) {
    console.log(e);
  }

  // try {
  //   fb.initIntercom(state);
  // } catch (e) {
  //   console.log(e);
  // }

  try {
    fb.initCrisp(state);
  } catch (e) {
    console.log(e);
  }

  try {
    clarity("set", "name", state.user.name);
    clarity("set", "email", state.user.email);
  } catch (e) {
    console.log("clarity error", e);
  }

  //window.fcm = firebaseMessaging

  function registerFcmMessageListener() {
    console.log("FCM Message Listner");
    firebaseMessaging.requestPermission().then(() => {
      window.firebaseMessaging
        .getToken()
        .then((fcmToken) => {
          console.log("Got tken", fcmToken);
          dispatch("updateToken", fcmToken);
        })
        .catch((error) => {
          console.log("Token Failure ", error);
        });

      // firebaseMessaging
      //   .onTokenRefresh()
      //   .then((fcmToken) => {
      //     // console.log("Got tken", fcmToken);
      //     dispatch("updateToken", fcmToken);
      //   })
      //   .catch((error) => {
      //     console.log("Token Failure ", error);
      //   });

      firebaseMessaging.onTokenRefresh(() => {
        firebaseMessaging
          .getToken()
          .then((refreshedToken) => {
            console.log("Token refreshed.");
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            dispatch("updateToken", refreshedToken);
          })
          .catch((error) => {
            console.log("Unable to retrieve refreshed token ", error);
          });
      });

      firebaseMessaging.onMessage((payload) => {
        console.log("Message received fcm: ", payload);
        // ...
        //  checkForRealtime(payload)
        //                dispatch('getNotifications');
      });
    });
  }
  registerFcmMessageListener();
  // Notification.requestPermission()
  //   .then(() => {
  //     // User has authorised
  //     // console.log("User has authorised fcm");
  //     try {
  //       registerFcmMessageListener();
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   })
  //   .catch((error) => {
  //     // User has rejected permissions
  //     console.log("User has rejected fcm permissions, error = ", error);
  //     try {
  //       registerFcmMessageListener();
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   });
};

fb.log = function(eventName, eventMeta) {
  console.log("Inside fb log ", eventName, eventMeta);
  //if (mobile()) {
  //    window.FirebasePlugin.logEvent(eventName, eventMeta);
  //  } else {

  if (process.env.NODE_ENV != "production") {
    return;
  }

  try {
    // Vue.gtm.push({
    //   event: eventName,
    // });

    var data = { event: eventName, ...eventMeta };

    Vue.gtm.trackEvent(data);
    console.log("Tracking GTM 1 doen");
  } catch (e) {
    console.log("GTM 1 Err", e);
  }

  try {
    firebaseAnalytics.logEvent(eventName, eventMeta);
  } catch (e) {
    console.log("Analytics Error ", e);
  }

  try {
    console.log("Saving event ", eventName);
    event(eventName, eventMeta);
  } catch (e) {
    console.log("Gtag error ", e);
  }

  // try {
  //   if (eventName == "purchase") {
  //     window.Intercom("trackEvent", "purchased-item", eventMeta);
  //   } else {
  //     window.Intercom("trackEvent", eventName, eventMeta);
  //   }
  // } catch (e) {
  //   console.log(e);
  // }

  try {
    if (eventName == "purchase") {
      $crisp.push(["set", "session:event", [[["purchase", eventMeta]]]]);
    } else {
      $crisp.push(["set", "session:event", [[[eventName, eventMeta]]]]);
    }
  } catch (e) {
    console.log(e);
  }

  try {
    clarity("set", eventName, eventMeta);
  } catch (e) {
    console.log(e);
  }
  // window.smartlook("track", eventName, eventMeta);

  // }
};

fb.screen = function(screen) {
  //if (mobile()) {
  // window.FirebasePlugin.setScreenName(screen);
  //} else {
  firebaseAnalytics.setCurrentScreen(screen);
  try {
    screenview(screen);
  } catch (e) {
    console.log(e);
  }
  //}
};

fb.logout = function() {
  if (mobile()) {
    window.FirebasePlugin.setAutoInitEnabled(false, function() {
      // console.log("Auto init has been disabled ");
      window.FirebasePlugin.unregister();
    });
  } else {
    firebaseMessaging.deleteToken();
  }
};

export default fb;
