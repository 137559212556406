import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import moment from "moment-timezone";
import { encode } from "js-base64";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});
export default new Vuex.Store({
  state: {
    paginate: {
      "lists&contacts": {
        page: 1,
        itemsPerPage: 20,
      },
    },
    // selectedList: "",
    selectedTeam: "",
    selectedList: "",
    loading: false,
    razorkey: process.env.VUE_APP_RZPKEY,
    paddleKey: process.env.VUE_APP_PADDLEKEY,
    monthlyProd: process.env.VUE_APP_MONTHLYPROD,
    yearlyProd: process.env.VUE_APP_YEARLYPROD,
    voiceBlastProduct: process.env.VUE_APP_VOICEBLAST,
    whatsappCreditProduct: process.env.VUE_APP_WHATASPPPADDLE,
    isPaddleStaging: process.env.VUE_APP_PADDLESANDBOX,
    teams: null,
    user: false,
    // allUsers: null,
    companyDetails: null,
    dispoList: [],
    fieldList: [],
    navbar: true,
    skipLogin: false,
    ENDPOINT: process.env.VUE_APP_ENDPOINT,
    UIENDPOINT: process.env.VUE_APP_UIENDPOINT,
    APPAPK: process.env.VUE_APP_APPAPK,
    headers: [
      {
        text: "Phone",
        align: "left",
        sortable: false,
        value: "phone",
      },
      {
        value: "secondPhone",
        text: "Secondary Phone",
        align: "left",
        sortable: false,
      },
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name",
      },
      {
        value: "companyName",
        text: "Company Name",
        align: "left",
        sortable: false,
      },
      {
        text: "Email",
        align: "left",
        sortable: false,
        value: "email",
      },
      {
        text: "Disposition",
        align: "center",
        sortable: true,
        value: "dispo",
      },
      {
        text: "Address",
        align: "center",
        sortable: true,
        value: "address",
      },
      {
        text: "Extra",
        align: "left",
        sortable: false,
        value: "extra",
      },
      {
        text: "Remarks",
        align: "left",
        sortable: false,
        value: "remarks",
      },
      {
        text: "Note",
        align: "left",
        sortable: false,
        value: "note",
      },
      {
        text: "Total Duration",
        align: "left",
        sortable: false,
        value: "TotalDuration",
      },
      {
        text: "Created On",
        align: "left",
        sortable: true,
        value: "createdOn",
      },
    ],
    reportHeaders: [
      {
        text: "Name",
        align: "left",
        sortable: false,
        value: "name",
        icon: "",
      },
      {
        text: "Phone",
        align: "left",
        sortable: false,
        value: "phone",
        icon: "",
      },
      {
        text: "Date",
        align: "left",
        sortable: false,
        value: "date",
        icon: "",
      },
      {
        text: "Time",
        align: "left",
        sortable: false,
        value: "time",
        icon: "",
      },
      {
        text: "Duration",
        align: "left",
        sortable: false,
        value: "Duration",
        icon: "mdi-timer",
      },
      {
        text: "Time Spent",
        align: "left",
        sortable: false,
        value: "TimeSpent",
        icon: "mdi-clock",
      },
      {
        text: "Dialer",
        align: "left",
        sortable: false,
        value: "defaultDialer",
        icon: "",
      },
      {
        text: "Called/Received By",
        align: "left",
        sortable: false,
        value: "accounts.name",
        icon: "",
      },
      {
        text: "Disposition",
        align: "left",
        sortable: false,
        value: "dispo",
        icon: "",
      },
      {
        text: "Remarks",
        align: "left",
        sortable: false,
        value: "remarks",
        icon: "",
      },
      {
        text: "Notes",
        align: "left",
        sortable: false,
        value: "note",
        icon: "",
      },
      {
        text: "List",
        align: "left",
        sortable: false,
        value: "list.name",
        icon: "",
      },
      {
        text: "Team",
        align: "left",
        sortable: false,
        value: "list.teams.name",
        icon: "",
      },
      {
        text: "Template",
        align: "left",
        sortable: false,
        value: "template.title",
        icon: "",
      },
      {
        text: "Recording",
        align: "left",
        sortable: false,
        value: "recording.name",
        icon: "",
      },
    ],
    filters: [],
    predicates: [],
    matchType: "or",
    config: {
      useVerification: eval(process.env.VUE_APP_USEVERIFICATION),
    },
    countDownTimer: 5,
    dialUsing: "Default Dialer",
    darkMode: false,
  },
  plugins: [vuexLocal.plugin],
  mutations: {
    setSelectedTeam(state, payload) {
      state.selectedTeam = payload;
    },
    setSelectedList(state, payload) {
      state.selectedList = payload;
    },
    setTeams(state, payload) {
      state.teams = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setCompany(state, payload) {
      state.companyDetails = payload;
    },
    setDispoList(state, payload) {
      state.dispoList = payload;
    },
    setFieldList(state, payload) {
      state.fieldList = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setHeaders(state, payload) {
      state.headers = payload;
    },
    setReportHeaders(state, payload) {
      state.reportHeaders = payload;
    },
    setPagination(state, payload) {
      state.paginate = payload;
    },
    setNavbar(state, payload) {
      state.navbar = payload;
    },
    setFilters(state, payload) {
      state.filters = payload;
    },
    setPredicates(state, payload) {
      state.predicates = payload;
    },
    setMatchType(state, payload) {
      state.matchType = payload;
    },
    setCountDownTimer(state, payload) {
      state.countDownTimer = payload;
    },
    setDialUsing(state, payload) {
      state.dialUsing = payload;
    },
    setSkipLogin(state, payload) {
      state.skipLogin = payload;
    },
    setDarkMode(state, payload) {
      state.darkMode = payload;
    },
  },
  actions: {
    updateToken({ commit, dispatch, state }, token) {
      Vue.http.patch(state.ENDPOINT + "/accounts/" + state.user.id, {
        gcmToken: token,
      });
    },
    externalLogin({ commit, dispatch, state }, pd) {
      Vue.http.headers.common["authorization"] = pd.token;

      Vue.http
        .get(state.ENDPOINT + "/accounts/" + pd.user)
        .then(async (payload) => {
          console.log("Got user from server ", payload.body);
          payload = payload.body;
          payload.token = pd.token;
          commit("setLoading", true);
          dispatch("getCompanyDetails", payload);
          commit("setUser", payload);
          commit("setNavbar", false);
          dispatch("getDispos", payload);
          dispatch("getFields", payload);
          dispatch("getTeams", payload);
          commit("setLoading", false);
          pd.next({
            name: pd.page,
          });
        })
        .catch((e) => console.log);
    },
    refreshUser({ commit, dispatch, state }) {
      Vue.http
        .get(state.ENDPOINT + "/accounts/" + state.user.id)
        .then(async (payload) => {
          payload = payload.body;
          payload.token = state.user.token;
          commit("setLoading", true);
          commit("setUser", payload);
          dispatch("getDispos", payload);
          dispatch("getFields", payload);
          dispatch("getTeams", payload);
          dispatch("getCompanyDetails", payload);
          commit("setLoading", false);
        });
    },
    async setUser({ commit, dispatch, state }, payload) {
      commit("setLoading", true);
      commit("setUser", payload);
      await dispatch("getCompanyDetails", payload);
      await dispatch("getDispos", payload);
      await dispatch("getFields", payload);
      await dispatch("getTeams", payload);
      commit("setLoading", false);
    },

    getDispos({ commit, dispatch, state }, payload) {
      Vue.http
        .get(
          `${state.ENDPOINT}/dispos?filter[where][companyId]=${payload.companyId}`
        )
        .then((response) => {
          commit("setDispoList", response.body);
        })
        .catch((e) => {
          commit("setLoading", false);
        });
    },

    getFields({ commit, dispatch, state }, payload) {
      Vue.http
        .get(
          `${state.ENDPOINT}/fields?filter[where][companyId]=${payload.companyId}`
        )
        .then((response) => {
          commit("setFieldList", response.body);
        })
        .catch((e) => {
          commit("setLoading", false);
        });
    },

    // get the company details
    getCompanyDetails({ state, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        var filter = {
          include: [
            {
              relation: "plan",
              scope: {
                include: ["coupons"],
              },
            },
          ],
        };
        Vue.http
          .get(
            `${state.ENDPOINT}/companies/${
              payload.companyId
            }?filter=${encodeURIComponent(JSON.stringify(filter))}`
          )
          .then((response) => {
            commit("setCompany", response.body);
            resolve(response.body);
          })
          .catch((err) => {
            console.log("Error in subscription", err);
          });
      });
    },

    // get the teams
    getTeams({ state, commit, dispatch }, payload) {
      Vue.http
        .get(`${state.ENDPOINT}/accounts/fetch-teams`)
        .then((response) => {
          response = response.body;
          var teams = response;

          // push all teams option
          if (payload.role == "Manager" || payload.role == "Teammanager") {
            teams.push({
              id: "allTeams",
              name: "All Teams",
            });
          }

          var teamsId = "";
          if (
            payload.role.toLowerCase() == "manager" ||
            payload.role.toLowerCase() == "teammanager"
          ) {
            teamsId = teams[0].id;
          } else {
            teamsId = payload.teams[0].id;
          }
          // teamsId
          commit("setSelectedTeam", teamsId);
          commit("setTeams", teams);
        })
        .catch((e) => {
          //Error
          commit("setLoading", false);
        });
    },
  },
  modules: {},
  getters: {
    navbar(state) {
      return state.navbar;
    },
    paginate(state) {
      return state.paginate;
    },
    headers(state) {
      return state.headers;
    },
    reportHeaders(state) {
      return state.reportHeaders;
    },
    fieldList(state) {
      return state.fieldList;
    },
    dispoList(state) {
      return state.dispoList;
    },
    monthlyProd(state) {
      return state.monthlyProd;
    },
    yearlyProd(state) {
      return state.yearlyProd;
    },
    paddleKey(state) {
      return state.paddleKey;
    },
    isPaddleStaging(state) {
      return state.isPaddleStaging;
    },
    selectedTeam(state) {
      return state.selectedTeam;
    },
    teams(state) {
      return state.teams;
    },
    user(state) {
      return state.user;
    },
    ENDPOINT(state) {
      return state.ENDPOINT;
    },
    filters(state) {
      return state.filters;
    },
    predicates(state) {
      return state.predicates;
    },
    matchType(state) {
      return state.matchType;
    },
    // allUsers(state) {
    //   return state.allUsers;
    // },
    companyDetails(state) {
      return state.companyDetails;
    },
    selectedList(state) {
      return state.selectedList;
    },
    config(state) {
      return state.config;
    },
    countDownTimer(state) {
      return state.countDownTimer;
    },
    skipLogin(state) {
      return state.skipLogin;
    },
    dialUsing(state) {
      return state.dialUsing;
    },
  },
});
