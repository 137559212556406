

var featureControl = {}

featureControl.features = {


    pipeline : false


}

featureControl.enabled = function(name) {
    return featureControl.features[name]
}


export default featureControl